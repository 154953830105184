import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Mint, { MintProps } from "./Mint";

import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import FlareIcon from "@mui/icons-material/Flare";
import HouseSidingIcon from "@mui/icons-material/HouseSiding";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import BuildIcon from "@mui/icons-material/Build";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import PlainSheep from "./images/plain.png";
import KingSheep from "./images/king.gif";

import CasualSheep from "./images/casuals.gif";
import HipsterSheep from "./images/hipsters.gif";
import ProfessionalSheep from "./images/professionals.gif";
import SuperheroSheep from "./images/superheroes.gif";
import IronmanSheep from "./images/ironman.jpg";
import HawaiiSheep from "./images/hawaii.jpg";
import MarioSheep from "./images/mario.jpg";
import SquirtleSheep from "./images/squirtle.jpg";
import PromoVideo from "./videos/solsheeppromo.mp4";
import SolSheepSong from "./videos/SolSheep Song.mpeg";

import Discord from "./images/discord.svg";
import Twitter from "./images/twitter.svg";

import theme from "./theme";

const Home = (props: MintProps) => {
  const Hero = styled.div`
    position: relative;
    box-sizing: border-box;
    padding: 4rem 2rem 8rem 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${theme.colors.pink};
    width: 100%;
  `;

  const PromoVid = styled.video`
    position: relative;
    padding: 1rem 1rem 1rem 1rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.blue};
  `;

  const PromoSong = styled.audio`
    position: relative;
    padding: 1rem 1rem 1rem 1rem;
  `;

  const Body2 = styled.div`
    position: relative;
    padding: 4rem 2rem 8rem 2rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.blue};
  `;

  const Body1 = styled.div`
    padding: 4rem 2rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.green};
  `;

  const Body3 = styled.div`
    position: relative;
    padding: 4rem 2rem 8rem 2rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.pink};
  `;

  const Body4 = styled.div`
    position: relative;
    padding: 4rem 2rem 8rem 2rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.yellow};
  `;

  const BodyRoadMap = styled.div`
    position: relative;
    padding: 4rem 2rem 8rem 2rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.grey};
  `;

  const Grid = styled.div`
    width: 100%;
    max-width: 1200px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4em;
    box-sizing: border-box;
    margin: 0 0 2rem 0;
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
    }
  `;

  const Square = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: start;
  `;

  const Details = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;
  `;

  const DetailsTitle = styled.h3`
    font-size: 1.5rem;
    margin: 0rem 0rem 0.5rem 0rem;
  `;

  const DetailsDescription = styled.p`
    font-size: 1.2rem;
    line-height: 2rem;
    font-family: "Maven Pro,sans-serif";
    font-weight: 700;
    color: ${theme.colors.darkgrey};
  `;

  const RoadmapTitle = styled.h3`
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0rem 0rem 0rem 0rem;
  `;

  const RoadmapDescription = styled.p`
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: ${theme.colors.darkgrey};
    margin-bottom: 4rem;
    font-family: "Maven Pro,sans-serif";
    font-weight: 700;
  `;

  const FAQDescription = styled.p`
    font-size: 1.2rem;
    line-height: 2rem;
    color: ${theme.colors.darkgrey};
    margin-bottom: 1.5rem;
    font-family: "Maven Pro,sans-serif";
    font-weight: 700;
  `;

  const Heading = styled.h1`
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
    color: white;
    font-size: 5rem;
    margin: 2rem 0rem;
    @media (min-width: 600px) and (max-width: 1023px) {
      font-size: 3rem;
      -webkit-text-stroke-width: 2px;
    }
    @media (max-width: 600px) {
      font-size: 2rem;
      -webkit-text-stroke-width: 1px;
    }
  `;

  const Heading2 = styled.h1`
    background-color: red;
    padding: 2rem;
    border: 0.5rem solid white;
    color: white;
    font-size: 3rem;
    transform: rotate(-10deg);
    z-index: 9999;
    margin: -2rem 0rem 0rem 0rem;
    @media (min-width: 600px) and (max-width: 1023px) {
      font-size: 2rem;
      -webkit-text-stroke-width: 2px;
    }
    @media (max-width: 600px) {
      font-size: 1.5rem;
      -webkit-text-stroke-width: 1px;
    }
  `;

  const Subheading = styled.h2`
    margin: 2rem 0rem;
    color: black;
    line-height: 1.5rem;
    font-size: 1rem;
  `;

  const SubHeadingDescription = styled.p`
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${theme.colors.darkgrey};
    margin-bottom: 2rem;
  `;

  const SectionHeading = styled.h2`
    font-size: 2.5rem;
    line-height: 4rem;
    margin: 4rem 0rem;
    @media (min-width: 600px) and (max-width: 1023px) {
      font-size: 2rem;
    }
    @media (max-width: 600px) {
      font-size: 1.5rem;
    }
  `;

  const scrolling = keyframes`
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
  `;

  const Sheeps = styled.div`
    bottom: 0;
    position: absolute;
    background-image: url(${PlainSheep});
    background-size: 3rem;
    background-repeat: repeat-x;
    width: 100%;
    height: 3rem;

    animation: ${scrolling} 120s linear infinite;

    @media (max-width: 1023px) {
      animation: ${scrolling} 30s linear infinite;
    }
  `;

  const MainImage = styled.img`
    margin: 4rem 0 5rem 0;
    width: 12rem;
    box-shadow: 0 0 30px 15px #fff, /* inner white */ 0 0 50px 30px #f0f,
      /* middle magenta */ 0 0 70px 45px #0ff; /* outer cyan */
    border: 5px solid black;
  `;

  const Socials = styled.div`
    display: flex;
    margin: 0 0 2rem 0;
  `;

  const SocialIcon = styled.img`
    height: 3rem;
    padding: 1rem;
    margin: 0rem 1rem;
    border: 5px solid black;
    background-color: ${theme.colors.pink};
    cursor: pointer;
  `;

  const Image = styled.img`
    border: 5px solid black;
    width: 12rem;
    @media (max-width: 479px) {
      width: 3rem;
    }
    @media (min-width: 480px) and (max-width: 599px) {
      width: 6rem;
    }
  `;

  const Roadmap = styled.div`
    width: 100%;
    max-width: 1200px;
    font-size: 1.5rem;
  `;

  const Emoji = styled.span`
    font-size: 2rem;
    margin-left: 1rem;
  `;

  const types = [
    {
      name: "HeroSheep",
      image: SuperheroSheep,
      description: "The sheep that saves the day!",
    },
    {
      name: "PunkSheep",
      image: HipsterSheep,
      description: "The colorful characters of the flock.",
    },
    {
      name: "WageSheep",
      image: ProfessionalSheep,
      description: "Protect and serve the sheeps of Solana.",
    },
    {
      name: "ChillSheep",
      image: CasualSheep,
      description: "Eat, sleep, chill, repeat!",
    },
  ];

  const team = [
    {
      name: "Art Sheep",
      image: SquirtleSheep,
      description: "",
    },
    {
      name: "Community Sheep",
      image: IronmanSheep,
      description: "",
    },
    {
      name: "UI Sheep",
      image: HawaiiSheep,
      description: "",
    },
    {
      name: "Dev Sheep",
      image: MarioSheep,
      description: "",
    },
  ];

  return (
    <main>
      <Hero>
        <Heading>SolSheep</Heading>
        <Heading2>SOLD OUT</Heading2>
        <PromoVid className="videoTag" autoPlay loop muted playsInline>
          <source src={PromoVideo} type="video/mp4" />
        </PromoVid>
        <Subheading>SocialFi NFT:</Subheading>
        <SubHeadingDescription>
          A new paradigm for social NFTs in a DeFi world.
        </SubHeadingDescription>
        <Subheading>Introducing HODL-to-earn:</Subheading>
        <SubHeadingDescription>
          You hold your SolSheep, and you earn!
        </SubHeadingDescription>
        <Mint {...props} />
        <PromoSong className="audio" controls autoPlay loop>
          <source src={SolSheepSong} type="audio/mpeg" />
        </PromoSong>
        <Sheeps className="sheeps" />
      </Hero>
      <Body1>
        <SectionHeading>Our Socials</SectionHeading>
        <Socials>
          <SocialIcon
            onClick={() =>
              window.open("https://discord.gg/jSQYeQjFFY", "_blank")
            }
            src={Discord}
          />
          <SocialIcon
            onClick={() => {
              window.open("https://twitter.com/sheep_sol", "_blank");
            }}
            src={Twitter}
          />
        </Socials>
      </Body1>
      <Body2>
        <SectionHeading>The Sheeps</SectionHeading>
        <DetailsTitle>KingSheep</DetailsTitle>
        <MainImage src={KingSheep} />

        <Grid>
          {types.map((t) => (
            <Square>
              <Image src={t.image} />
              <Details>
                <DetailsTitle>{t.name}</DetailsTitle>
                <DetailsDescription>{t.description}</DetailsDescription>
              </Details>
            </Square>
          ))}
        </Grid>
      </Body2>
      <BodyRoadMap>
        <SectionHeading>The Roadmap</SectionHeading>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: theme.colors.pink, color: "#000000" }}
            contentArrowStyle={{
              borderRight: "7px solid " + theme.colors.pink,
            }}
            iconStyle={{ background: theme.colors.pink, color: "#000000" }}
            icon={<HouseSidingIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              {" "}
              Inside the Barns:
            </h3>
            <p>
              SocialFi Gameplan<Emoji>🎮</Emoji>
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: theme.colors.blue, color: "#000000" }}
            contentArrowStyle={{
              borderRight: "7px solid " + theme.colors.blue,
            }}
            iconStyle={{ background: theme.colors.blue, color: "#000000" }}
            icon={<AccessAlarmIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              {" "}
              Early Sheepers:
            </h3>
            <p>
              Pre-sale (1k)<Emoji>🎁</Emoji>
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: theme.colors.green, color: "#000000" }}
            contentArrowStyle={{
              borderRight: "7px solid " + theme.colors.green,
            }}
            iconStyle={{ background: theme.colors.green, color: "#000000" }}
            icon={<GroupWorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              {" "}
              Sheepers Unite:{" "}
            </h3>
            <p>
              Mint (9k)<Emoji>💏</Emoji>
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: theme.colors.yellow, color: "#000000" }}
            contentArrowStyle={{
              borderRight: "7px solid " + theme.colors.yellow,
            }}
            iconStyle={{ background: theme.colors.yellow, color: "#000000" }}
            icon={<ExitToAppIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              {" "}
              Escape from Barns (after 50% sold):{" "}
            </h3>
            <p>
              Four Kingdoms DAO creation<Emoji>🏰</Emoji>
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: theme.colors.purple, color: "#000000" }}
            contentArrowStyle={{
              borderRight: "7px solid " + theme.colors.purple,
            }}
            iconStyle={{ background: theme.colors.purple, color: "#000000" }}
            icon={<BuildIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              {" "}
              Buid-lers Invest (after 100% sold):{" "}
            </h3>
            <p>
              SolSheep Royalties Fund set up<Emoji>💰</Emoji>
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            iconStyle={{ background: theme.colors.red, color: "#000000" }}
            icon={<FlareIcon />}
          />
        </VerticalTimeline>
      </BodyRoadMap>
      <Body3>
        <SectionHeading>What is SolSheep?</SectionHeading>
        <Grid>
          <Details>
            <DetailsTitle>SocialFi NFT Platform</DetailsTitle>
            <DetailsDescription>
              <ul>
                <li>
                  SolSheep is a SocialFi NFT platform that allows social
                  interactions in a DeFi + NFT world.
                </li>
                <li>
                  Each SolSheep is a ticket to our HODL-to-earn mechanism and
                  allows participation in the construction of four revolutionary
                  SolSheep Kingdoms (think DAOs).
                </li>
              </ul>
            </DetailsDescription>
          </Details>
          <Details>
            <DetailsTitle>
              <Emoji>🏰</Emoji>
            </DetailsTitle>
            <DetailsDescription>
              <ul>
                <li>
                  After minting 10,000 SolSheeps, each Kingdom (easily
                  identified by background colors of each SolSheep NFTs) will
                  form a social hierarchy that is completely driven by SolSheep
                  holders.{" "}
                </li>
                <li>
                  Four kingdoms will be created based on background color of
                  your SolSheep
                </li>
              </ul>
            </DetailsDescription>
          </Details>
        </Grid>
        <Grid>
          <Details>
            <DetailsTitle>HODL-to-Earn</DetailsTitle>
            <DetailsDescription>
              <ul>
                <li>
                  At initial minting, drawing KingSheep or HeroSheep will give
                  you a large chunk of NFT sale proceeds
                </li>
                <li>
                  After minting, holding each SolSheep will give you a varying
                  economic return (KingSheep can have more!) based on
                  decentralized marketplace transaction volume
                </li>
                <li>
                  SolSheep Fund will be set up from the remaining royalties fees
                  and be completely managed by the community to purchase other
                  Solana NFTs
                </li>
              </ul>
            </DetailsDescription>
          </Details>
        </Grid>

        <SectionHeading>FAQ</SectionHeading>
        <Roadmap>
          <RoadmapTitle>When does pre-sale start?</RoadmapTitle>
          <FAQDescription>Oct 8th 12pm UTC!</FAQDescription>
          <RoadmapTitle>When does minting start?</RoadmapTitle>
          <FAQDescription>Oct 10th 3pm UTC!</FAQDescription>
          <RoadmapTitle>How much does each SolSheep cost?</RoadmapTitle>
          <FAQDescription>
            0.3 SOL for pre-sale, 0.5 SOL for minting
          </FAQDescription>
          <RoadmapTitle>
            How does the SolSheepers who draw KingSheep or HeroSheep receive
            their rewards?
          </RoadmapTitle>
          <FAQDescription>
            10% of all sale proceeds will go to the 4 KingSheeps, while 5% of
            all sale proceeds will go to the 150 HeroSheeps. <br />
            In addition, 450 SolSheeps with Laser Eyes will be entitled to 3% of
            all proceeds. Hope luck is on your side!
          </FAQDescription>
          <RoadmapTitle>
            Will KingSheep always receive more royalties?
          </RoadmapTitle>
          <FAQDescription>
            Initially the 4 KingSheeps do, but that can change quickly depending
            on community votes. <br />
            Our dedicated team will maintain the flow of the system at first,
            but we will shift to a decentralized format as soon as we can.
          </FAQDescription>
          <RoadmapTitle>How does each Kingdom function?</RoadmapTitle>
          <FAQDescription>
            This is completely up to the SolSheep community! Democracy,
            autocracy, dictatorship? We live in a decentralized (and free) world
            <Emoji>🌍</Emoji>
          </FAQDescription>
        </Roadmap>
      </Body3>
      <Body4>
        <SectionHeading>The Team</SectionHeading>
        <Grid>
          {team.map((t) => (
            <Square>
              <Image src={t.image} />
              <Details>
                <DetailsTitle>{t.name}</DetailsTitle>
                <DetailsDescription>{t.description}</DetailsDescription>
              </Details>
            </Square>
          ))}
        </Grid>
        <Sheeps />
      </Body4>
    </main>
  );
};

export default Home;
